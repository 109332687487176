<template>
  <div class="course-shop-live">
    <div class="player">
      <div class="title">
        <span>{{ activeVideo.sectionName }}</span>
        <span @click="back">返回课程详情</span>
      </div>
      <div class="play-view">
        <div
          v-if="
            (activeVideo.sectionStage == 3 && activeVideo.liveSection.liveStatus == 2) ||
              activeVideo.sectionStage < 3
          "
          id="prism-player"
          class="prism-player"
        ></div>
        <div v-else class="liveMask">
          <img :src="activeVideo.liveSection.livePic" alt="" />
          <div class="mask">
            直播{{ ['编辑中', '待开始', '直播中', '已结束'][activeVideo.liveSection.liveStatus] }}
          </div>
        </div>
        <div :class="['expend', { isExpend: isExpend }]" @click="expend">
          <i v-if="!isExpend" class="el-icon-arrow-right"></i>
          <i v-else class="el-icon-arrow-left"></i>
        </div>
        <div v-show="!isExpend" class="play-menu">
          <div
            v-for="(item, index) in detail.sections"
            :key="item.sectionId"
            :class="['play-menu-item', { active: item.sectionId == sectionId }]"
            @click="toPlay(item)"
          >
            <div class="name">
              <div class="live_icon">{{ ['回放', '直播'][item.sectionStage - 2] }}</div>
              <span class="sectionName"
                >第<span v-show="index < 9">0</span>{{ index + 1 }}节 {{ item.sectionName }}</span
              >
            </div>
            <div class="time date">
              {{ item.liveSection.time }}
            </div>
            <div class="liveStatus">
              <template v-if="item.liveSection.liveStatus == 2">
                <img src="@/assets/live/live_status_icon.png" alt="" />
                <span class="status live">直播中</span>
              </template>
              <span v-if="item.liveSection.liveStatus == 3" class="status end">已结束</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { courseShopInfo, videoPlayInfo } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseShopLive',
  data() {
    return {
      player: null,
      detail: {},
      productId: '',
      sectionId: '',
      activeVideo: {},
      isExpend: false,
    }
  },
  watch: {
    $route() {
      window.location.reload()
    },
  },
  mounted() {
    this.productId = this.$route.params.productId
    this.sectionId = this.$route.query.sectionId
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const [res, err] = await to(courseShopInfo({ productId: this.productId }))
      if (err) return this.$message.warning(err.msg)
      this.detail = res.data
      for (let i = 0; i < this.detail.sections.length; i++) {
        const section = this.detail.sections[i]
        if (section.sectionId == this.sectionId) {
          this.activeVideo = section
          this.activeVideo.url =
            section.sectionStage == 3
              ? section.liveSection.flvUrl
              : await this.getPlayUrl(section.videoPlatform, section.videoId)
          this.init()
        }
      }
    },
    init() {
      // eslint-disable-next-line no-undef
      this.player = new Aliplayer({
        id: 'prism-player',
        cover:
          this.activeVideo.sectionStage == 3
            ? this.activeVideo.liveSection.livePic
            : this.activeVideo.coverImageUrl,
        source: this.activeVideo.url,
        width: '100%',
        autoplay: true,
        isLive: this.activeVideo.sectionStage == 3,
        rePlay: false,
        playsinline: true,
        preload: true,
        controlBarVisibility: 'hover',
        useH5Prism: true,
      })
    },
    async toPlay(item) {
      if (!item.isAuth) return this.$message.warning('请先购买该课程')
      this.$router.replace(`/course/live/${this.productId}?sectionId=${item.sectionId}`)
      // this.activeVideo = item
      // this.activeVideo.url =
      //   item.sectionStage == 3
      //     ? item.liveSection.flvUrl
      //     : await this[
      //         { 0: 'getPlayUrl_ali', 2: 'getPlayUrl_huawei', 3: 'getPlayUrl_ksb' }[
      //           item.videoPlatform
      //         ]
      //       ](item.videoId)
      // this.init()
    },
    async getPlayUrl(videoPlatform, videoId) {
      const [res, err] = await to(videoPlayInfo({ videoPlatform, videoId }))
      if (err) {
        this.$message.warning(err.msg)
        return false
      }
      return res.data.videoUrl
    },
    back() {
      this.$router.go(-1)
    },
    expend() {
      this.isExpend = !this.isExpend
    },
  },
}
</script>
<style lang="scss" scoped>
.course-shop-live {
  .title {
    // width: 1200px;
    height: 70px;
    opacity: 1;
    background: #232323;
    font-size: 20px;
    color: #ffffff;
    line-height: 70px;
    padding-left: 27px;
    box-sizing: border-box;
    span {
      &:last-of-type {
        cursor: pointer;
        float: right;
        font-size: 14px;
        color: #ff7b33;
        margin-right: 336px;
      }
    }
  }
  .play-view {
    position: relative;
    // width: 1200px;
    height: 490px;
    display: flex;
    .prism-player {
      flex: 1;
      height: 100% !important;
      width: 100%;
    }
    .liveMask {
      position: relative;
      flex: 1;
      height: 100% !important;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(19, 19, 19, 0.6);
        font-size: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .expend {
      cursor: pointer;
      position: absolute;
      right: 330px;
      top: 0;
      width: 21px;
      height: 490px;
      line-height: 490px;
      background: rgba(19, 19, 19, 0.6);
      i {
        font-size: 20px;
        color: #ccc;
      }
      &.isExpend {
        right: 0;
      }
    }
    .play-menu {
      width: 330px;
      height: 490px;
      box-sizing: border-box;
      background: #131313;
      padding: 0 20px;
      overflow-y: scroll;
      .play-menu-item {
        position: relative;
        cursor: pointer;
        padding: 20px 0;
        border-bottom: 1px solid #333333;
        &.active {
          .name {
            color: #409eff;
          }
        }

        .name {
          font-size: 14px;
          color: #fff;
          line-height: 14px;
          margin-bottom: 7px;
          display: flex;
          align-items: center;
          .sectionName {
            flex: 1;
            padding-right: 70px;
            @extend %one-line-text;
          }
          .live_icon {
            width: 43px;
            height: 22px;
            background: linear-gradient(270deg, #46c597, #56d09a 30%, #57d19a 35%, #66da9d);
            border-radius: 4px;
            font-size: 12px;
            text-align: center;
            color: #ffffff;
            line-height: 22px;
            margin-right: 6px;
          }
          .freelive {
            width: 58px;
            height: 18px;
            background: #ff3f29;
            border-radius: 4px;
            font-size: 12px;
            text-align: center;
            color: #ffffff;
            line-height: 18px;
            margin-left: 9px;
          }
          img {
            width: 15px;
            height: 15px;
            margin-right: 6px;
          }
        }
        .time {
          font-size: 12px;
          color: #909399;
          padding-left: 23px;
        }
        .date {
          padding-left: 49px;
        }
        .liveStatus {
          display: flex;
          align-items: center;
          position: absolute;
          right: 5px;
          top: 20px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
          .status {
            font-size: 14px;
            color: #909399;
            &.live {
              color: #ff7b33;
            }
          }
        }
      }
    }
  }
}
</style>
